@import 'globals.scss';

$primary: $brandColor;
$info: $darkShade;
$success: #598e70;
$warning: #d67e38;
$danger: #f44336;

$font-family: 'Lato';
$font-file: '../assets/latoFont/Lato-Medium';
@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file+'.eot');
    src: url($font-file+'.eot?#iefix') format('embedded-opentype'),
  url($font-file+'.woff') format('woff'),
  url($font-file+'.ttf') format('truetype'),
  url($font-file+'.svg#aller') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}

$font-family-base: 'Lato', sans-serif;

$navbar-padding-y: 0;
$navbar-padding-x: 0;

$navbar-light-toggler-border-color: transparent;

@import "../../node_modules/bootstrap/scss/bootstrap";
