@import "bootstrapTheme";

//Scroll To Top
#scrollTop {
  opacity: 0;
  transition: opacity 0.4s ease;

  border-radius: 100px;

  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
}

//footer
#footer {
  margin-top: 4rem;

  text-align: center;
  color: $backgroundTextColor;
}
