@import "../bootstrapTheme";

//This aligns the container for the whole problem section
#problem {
  margin: 2rem 0rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

//This container has the list of icons (everything besides the h1 tag in the problem section)
#problemIcon {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin: 2rem 0rem;
}

//Not to be confused with the above tag. This is for the container for each icon and text
.problemIconContainer {
  text-align: center;
  margin: 0rem 2rem;
}

//This styles the icons themselves
.problemIcons {
  color: $primary;
  margin: 1.25rem;
}

//This styles each icon's caption
.iconCaption {
  max-width: 250px;
  font-size: 1.05rem;
}

//The wrapIcons divs are just there to make the icons wrap two at a time.
//The parent div is flex-wrap too, so when the screen gets too small, the wrapIcons divs get stacked on top of each other.
//When the screen gets even smaller, the wrapIcons divs are flex-wrap, so the icons will be stacked on top of each other, so you'll have all 4 icons on top of each other.
.wrapIcons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
