// From http://colormind.io/bootstrap/
$lightShade: #F8F8F8;
$lightAccent: #A889CB;
$brandColor: #7F46BC;
$darkAccent: #876B96;
$darkShade: #2A213A;

$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;

$backgroundTextColor: #747d88;
