@import 'bootstrapTheme.scss';

//Removes the underline that's on links by default for the page navigation buttons
.nav-item:hover {
  a:hover {
    text-decoration: none;
  }

  text-decoration: none;
}

.navbar {
  background-color: $body-bg;
}

//How I do the background on the nav buttons is pretty weird. The nav button text is surrounded by the nav item which is surrounded by a container which is surrounded by the navbar.
// In order to make sure that setting the nav item background goes all the way to the bottom of the navbar, there has to be no padding on any parent elements.
// That's why the padding variables are set in bootstrapTheme.scss. Then I just set the padding on the nav items to be larger than anything else.
// This will make them extend to the bottom of the navbar. Now I just have to add coloring
html {
  //These two margins are set to adding padding in the mobile view. I use margin because padding causes a weird bug where the navigation animation gets removed.
  #brandName {
    margin: 0.5rem 1rem !important;
  }

  .navbar-toggler-icon {
    margin: 0.5rem 0.5rem !important;
  }

  //Adds padding to the page navigation buttons
  .nav-item {
    padding: 1.4rem 1.0rem;
  }

  //Navbar coloring
  .nav-item {
    color: $backgroundTextColor;
    transition: 0.4s ease;

    .navbar-link {
      color: $backgroundTextColor;
    }
  }

  .active, .nav-item:hover {
    .navbar-link {
      color: white;
    }

    background-color: $brandColor;
  }
}

.nav-item {
  //This will align the nav text in the center when not in the mobile dropdown view. In that case, I want the text on the left.
  @media only screen and (min-width: $sm) {
    text-align: center;
  }

  //This aligns the text vertically.
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navbar-link {
  font-size: 1.1rem;
}

//This sets the brand name in the navbar
#brandName {
  color: $brandColor;
  font-weight: 600;
  font-size: 1.6rem;
  margin: 0;
}

//Since we use a function on the anchor tags and not a link, we had to re-add the cursor style for links with no href
.nav-item:hover, #brandName:hover {
  cursor: pointer;
}

