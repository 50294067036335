@import '../bootstrapTheme';

//This is the styling for the main container. It vertically and horizontally aligns the container contents
#aboutUs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  //Flex-wrap doesn't work well for this purpose. The image just keeps getting smaller and smaller without going underneath the text. This is best.
  @media only screen and (max-width: $md) {
    flex-direction: column;
  }
}

//This adds margin and tells this component to be 2/5ths of the total width
#aboutUsText {
  margin: 2rem;
  flex: 2;
}

//The image is 3/5ths of the total width
#aboutUsImageContainer {
  margin: 2rem;
  flex: 3;

  padding: 10px; //The purple background adds 10px on all sides, but doesn't move the DOM around since it uses position relative. This will add the appropriate spacing.
}

//This is the image itself. To create the purple background, we have to move the image the desired amount from the edge of the purple background
#aboutUsImage {
  position: relative;
  bottom: 20px;
  left: 20px;
}

//For the purple background to be centered, we have to move it half of the distance to the image in the opposite direction.
//The background will be 10px off center to the right. The image will be 20px to the left of the purple background, or 10px off center to the left
#aboutUsImageBackground {
  background-color: $brandColor;

  position: relative;
  top: 10px;
  right: 10px;
}
